<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.cname">
      <div class="line-title">【 疾&nbsp&nbsp&nbsp&nbsp&nbsp病 】</div>
      <div class="line-text" v-html="data.cname"></div>
    </div>
    <div class="line" v-if="data.ename">
      <div class="line-title">【 英&nbsp文&nbsp名 】</div>
      <div class="line-text" v-html="data.ename"></div>
    </div>
    <div class="line" v-if="data.alias">
      <div class="line-title">【 别&nbsp&nbsp&nbsp&nbsp&nbsp名 】</div>
      <div class="line-text" v-html="data.alias"></div>
    </div>
    <div class="line" v-if="data.icd">
      <div class="line-title">【 ICD代码 】</div>
      <div class="line-text" v-html="data.icd"></div>
    </div>
    <div class="line" v-if="data.category_path">
      <div class="line-title">【 分&nbsp&nbsp&nbsp&nbsp&nbsp类 】</div>
      <div class="line-text" v-html="data.category_path"></div>
    </div>

    <div class="column" v-if="data.pathogeny">
      <div class="line-title">【 病因和发病机制研究进展 】</div>
      <div class="line-text" v-html="data.pathogeny"></div>
    </div>
    <div class="column" v-if="data.diagnose">
      <div class="line-title">【 诊断研究进展 】</div>
      <div class="line-text" v-html="data.diagnose"></div>
    </div>
    <div class="column" v-if="data.cure">
      <div class="line-title">【 治疗和预防研究进展 】</div>
      <div class="line-text" v-html="data.cure"></div>
    </div>
    <div class="column" v-if="data.research">
      <div class="line-title">【 存在问题和研究热点 】</div>
      <div class="line-text" v-html="data.research"></div>
    </div>
    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '6')">下 载</a>
      </div>
    </div>

    <div class="foot-details" v-if="data.paper">
      <div class="line-title">【 相关文章 】</div>
      <div class="line-text">
        <ul v-html="data.paper"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.diseaseinfo">
      <div class="line-title">【 疾病基本信息 】</div>
      <div class="line-text">
        <ul v-html="data.diseaseinfo"></ul>
      </div>
    </div>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "DiseaseResearchDetails",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '6',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    }
  }
</script>
